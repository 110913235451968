import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container } from 'react-bootstrap';

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <Container className="py-1">
      <h1 className="mt-5 mb-4">Privacy Notice</h1>
      PromLens (accessible at <a href="https://promlens.com">promlens.com</a>) is a service offered by{' '}
      <Link to="/imprint">PromLabs GmbH</Link>, a German company. This notice details how we process personal data when you
      use this website. If you have any questions on how we process personal data, please{' '}
      <Link to="/imprint">contact us</Link>.<h4 className="mt-4">Request Logs</h4>
      <p>When using this website, we record certain information about each request from your browser:</p>
      <ul>
        <li>IP address of the requesting computer,</li>
        <li>Date and time of access,</li>
        <li>Name and URL of the requested page,</li>
        <li>Website from which access is obtained (referrer URL),</li>
        <li>
          Browser used and, where applicable, your computer’s operating system and the identity of your access provider;
        </li>
      </ul>
      <p>
        We use these server logs for the purposes of abuse prevention, troubleshooting, as well as to understand how the
        website is used and how to improve it.
      </p>
      <h4>PromQL Queries</h4>
      <p>
        When you enter textual PromQL queries on the website, the browser sends these queries to a backend that transforms
        these queries into a tree representation that is sent back to the browser. This processing is required to implement
        the tree-based view of a PromQL query on the website. We may use this query information to better understand how the
        service is used and to improve it.
      </p>
      <section>
        <h4 className="mt-4">Email and Contact Forms</h4>
        <p>
          If you email us directly or use one of our contact or notification signup forms, we will hold and process your
          name, email address, and other content of your communication for the purpose of resolving your inquiry, and for
          abuse prevention purposes.
        </p>
      </section>
      <h4 className="mt-4">Shareable Links</h4>
      <p>
        When you request the creation of a shareable link to a PromLens page, we store the entire page configuration
        (including textual and tree representations of PromQL queries and the selected visualization methods) in a database.
        We use this database to provide persisted shareable links that can be viewed by other people. We also store the
        creation date and time for each shareable link, as well as the date and time of each view of the shared link. We use
        this information to understand how the website it used and how to improve it.
      </p>
      <h4 className="mt-4">Amendments</h4>
      <p>
        This Privacy Notice is currently valid. It may be necessary to amend this Privacy Notice due to the development of
        the website or due to other requirements. You will be able to access the latest version of this Privacy Notice at any
        time at <a href="/privacy">https://promlens.com/privacy</a>.
      </p>
      <p>
        <i>Last modified: May 7, 2020</i>
      </p>
    </Container>
  </Layout>
);

export default PrivacyPage;
